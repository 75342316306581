import React, { Component } from 'react'
import { Checkbox, Image, Button, Form, Grid, Header, Message, Segment, Dimmer, Loader } from 'semantic-ui-react'
import { Redirect } from 'react-router-dom'
import 'whatwg-fetch'
import banner from './header.png'
import event from './cover.png'
import ita from './ita.png'
import eng from './eng.png'
export default class Login extends Component {

  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      error: null,
      name: "",
      surname: "",
      email: "",
      company: "",
      terms: false,
      terms2: false,
      redirect: (localStorage.getItem("jwtFinal2023") !== null)
    }
  }
  
  login () {
    this.setState({loading: true})

    var headers = new Headers()
    headers.append("Content-Type", "application/json")

    var bodyObject = {
      name: this.state.name,
      surname: this.state.surname,
      email: this.state.email,
      company: this.state.company
    }

    if (!this.state.terms && !this.state.terms2) {
      this.setState({error: "Devi accettare i termini e le condizioni per continuare", loading: false});
      return;
    }

    fetch('https://siad2024pres-api.reportcongressi.com/register', {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(bodyObject)
    }).then(function(res) {
      if (res.ok) {
        res.json().then(function (data) {
          localStorage.setItem("jwtFinal2023", data.jwtToken)
          this.setState({redirect: true, loading: false})
        }.bind(this)).catch(function(err) {
          console.log(err)
        })
      } else {
        res.json().then(function (data) {
          this.setState({error: data.reason, loading: false})
        }.bind(this)).catch(function(err) {
          console.log(err)
        })
      }
    }.bind(this)).catch(function(err) {
      this.setState({loading: false, error: "Errore inaspettato, ricarica la pagina per riprovare."})
    }.bind(this))
    /*setTimeout(function () {
      this.setState({
        loading: false,
        error: true
      })
    }.bind(this), 3000)*/
  }

  render () {
    return (
      <div className='login-form'>
      {this.state.redirect && (
        <Redirect to="/" />
      )}
      {/*
        Heads up! The styles below are necessary for the correct render of this example.
        You can do same with CSS, the main idea is that all the elements up to the `Grid`
        below must have a height of 100%.
      */}
      <style>{`
        body > div,
        body > div > div,
        body > div > div > div.login-form {
          height: 100%;
        }
      `}</style>
      <Grid stretched style={{ marginBottom: "4vw" }}>
        <Grid.Column computer={16} tablet={16} mobile={16}>
          <img src={banner} style={{maxWidth: "100%", backgroundColor: "white"}} />
        </Grid.Column>
      </Grid>
      <Grid
        verticalAlign='middle'
      >
        <Grid.Column computer={10} tablet={16} mobile={16}>
          <img src={event} style={{ maxWidth: "100%", paddingLeft: 40 }} />
        </Grid.Column>
        <Grid.Column computer={5} tablet={16} mobile={16} style={{ maxWidth: 800, padding: "0 30px" }}>
          <Form size='large' onSubmit={this.login.bind(this)}>
              {this.state.loading && (
                <Dimmer active>
                  <Loader />
                </Dimmer>
              )}
              {this.state.error && (
                <Message negative>
                  <p>{this.state.error}</p>
                </Message>
              )}
              <Grid columns={1}>
              <Grid.Column>
                <Form.Input
                  fluid
                  placeholder="NOME/NAME*"
                  icon="id badge"
                  iconPosition="left"
                  value={this.state.name}
                  onChange={e => this.setState({name: e.target.value})}
                />
              </Grid.Column>
              <Grid.Column>
              <Form.Input
                  fluid
                  placeholder="COGNOME/SURNAME*"
                  icon="id badge"
                  iconPosition="left"
                  value={this.state.surname}
                  onChange={e => this.setState({surname: e.target.value})}
                />
              </Grid.Column>
              </Grid>
              <Grid columns={1} style={{ marginBottom: 10 }}>
                <Grid.Column>
                <Form.Input
                fluid
                icon="address book outline"
                iconPosition="left"
                placeholder="AZIENDA FILIALE/COMPANY BRANCH"
                value={this.state.company}
                onChange={e => this.setState({company: e.target.value})}
              />
                </Grid.Column>
              </Grid>
              <Form.Field
                control={Checkbox}
                style={{ textAlign: "left" }}
                onChange={(e, { checked }) => this.setState({terms: checked})}
                label={{ children: <b><a style={{color: "#00B13F"}} target="_blank" href="https://www.siad.com/documents/30774/639797/SIAD_+Privacy+GDPR+-+uk_clienti+attuali+e+potenziali.pdf/c8352a50-71d1-e654-3da6-936c0a850d87">Privacy Policy</a></b> }}
              />
              <Form.Field
                control={Checkbox}
                style={{ textAlign: "left" }}
                onChange={(e, { checked }) => this.setState({terms: checked})}
                label={{ children: <p><b style={{color: "#00B13F"}}>CONTENUTO RISERVATO.<br />È vietata la riproduzione e diffusione in qualunque forma, anche ai sensi dell'art. 12 del Codice Etico e di Comportamento.</b>
                <br /><b style={{color: "#6C6E88"}}>CONFIDENTIAL CONTENT.<br />Reproduction and dissemination in any form forbidden, including pursuant to article 12 of the Code of Ethics and Behavior.</b></p> }}
              />
              <Button className="register-button" color='green' size='large' style={{width: 'auto', display: 'inline-block' }}>START</Button>
          </Form>
        </Grid.Column>
      </Grid>
      <Grid
        textAlign='center'
        verticalAlign='middle'
      >
        <Grid.Column style={{ backgroundColor: "#00AE41", marginTop: 80, fontSize: 16, textAlign: "center", color: "white", padding: "20px 30px" }}>
          <p>
          Il player video utilizzato per la trasmissione sarà Vimeo e si consigliano browser come Chrome, Windows Edge o Safari per aprirlo.<br />
          Se vi siete loggati con anticipo rispetto all'inizio, potrebbe essere necessario fare un refresh della pagina.
          </p>
          <p>
          The video player used for the broadcast will be Vimeo and Chrome, Windows Edge or Safari are the suggested browsers to open it.<br />
          If you logged in earlier than start time, it may be necessary to refresh the page.
          </p>
        </Grid.Column>
      </Grid>
    </div>
    )
  }
}