import React, { Component } from 'react';
import './style.css';
import io from 'socket.io-client';
import Aux from "../../Components/Aux"
import { Redirect } from 'react-router-dom'
import { Image, Container, Grid, Form, Segment, Input, Button, Comment } from 'semantic-ui-react'
import banner from '../header.png'
import event from '../cover.png'
import dateFormat from 'dateformat'
import 'whatwg-fetch'

export default class Home extends Component {

  constructor(props) {
    super(props);

    this.state = {
      redirectToLogin: false,
      messageText: "",
      messages: [],
    }

    this.initSocket = this.initSocket.bind(this)

    if (localStorage.getItem("jwtFinal2023")) {
      fetch("https://siad2024pres-api.reportcongressi.com/old-messages").then(function (res) {
        return res.json()
      }).then(function (json) {
        this.setState({ messages: json })
        this.initSocket()
      }.bind(this)).catch(function (err) {
        console.log(err)
        this.initSocket()
      }.bind(this))

    } else {
      this.state.redirectToLogin = true
    }
  }

  initSocket() {
    this.socket = io("https://siad2024pres-api.reportcongressi.com", { query: "token=" + localStorage.getItem("jwtFinal2023") });
    this.socket.on("message.stream", function (body) {
      var newMessages = this.state.messages
      newMessages.push(body)
      this.setState({ messages: newMessages })
    }.bind(this));
  }

  componentDidMount() {
    if (this.chatbox) {
      this.chatbox.scrollTop = this.chatbox.scrollHeight
    }

    /*
    if (!this.state.redirectToLogin) {
      var PLAYBACK_URL = 'https://ff822e17db69.eu-west-1.playback.live-video.net/api/video/v1/eu-west-1.283426881077.channel.MCIL3zcq4zm3.m3u8';
              
      // Register Amazon IVS as playback technology for Video.js
      window.registerIVSTech(window.videojs);
      window.registerIVSQualityPlugin(window.videojs); // where videojs is the video.js variable

      // Initialize player
      var player = window.videojs('amazon-ivs-videojs', {
          techOrder: ["AmazonIVS"]
      }, () => {
          console.log('Player is ready to use!');
          // Play stream
          player.enableIVSQualityPlugin(); // where player is the instance of the videojs player
          player.src(PLAYBACK_URL);
      });
    }*/
  }

  componentDidUpdate() {
    if (this.chatbox) {
      this.chatbox.scrollTop = this.chatbox.scrollHeight
    }
  }

  sendMessage() {
    console.log(this.state.messageText);
    if (this.state.messageText === "") return;

    if (this.socket && this.socket.connected) {
      this.socket.emit("message.new", { message: this.state.messageText })
      this.setState({ messageText: "" })
    }
  }

  render() {
    return (
      <Aux>
        {this.state.redirectToLogin && (
          <Redirect to="/login" />
        )}
        <Grid stretched style={{ marginBottom: "4vw" }}>
          <Grid.Column computer={16} tablet={16} mobile={16} >
            <img src={banner} style={{ maxWidth: "100%", backgroundColor: "white" }} />
          </Grid.Column>
        </Grid>
        <Container fluid className="main-container">
          <Grid centered stretched>
            <Grid.Column computer={12} tablet={16} mobile={16}>
              <div className="ui embed">
              <iframe src="https://player.vimeo.com/video/907815995?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture"></iframe>
              </div>
            </Grid.Column>
            <style>
              {`
                @media(max-width: 990px) {
                  .hide-mobile {
                    display: none !important;
                  }
                }
              `}
            </style>
          </Grid>
          <Grid textAlign='center' verticalAlign='middle'>
            <Grid.Column style={{ marginTop: 80, fontSize: 16, textAlign: "center", color: "white", padding: "20px 10vw", backgroundColor: "#01AD47", }}>
              <p>
              Se perdete la connessione, basta fare un refresh della pagina senza bisogno di loggarsi nuovamente.<br/>
              Per qualsiasi necessità potete inviare una mail a siad_events@siad.eu o chiamare il +39 335 7763510.
              </p>
              <p>
                If you have line problems and lose signal, try refreshing the page. There is no need to log back in.<br />
                Should need anything, please send a email to siad_events@siad.eu or call +39 335 7763510.
              </p>
            </Grid.Column>
          </Grid>
        </Container>
      </Aux>
    );
  }
}