import React from 'react'
import Home from "./Views/Home/index"
import Login from "./Views/Login"
import { BrowserRouter as Router, Route } from 'react-router-dom'
import Aux from './Components/Aux'
import Moderation from './Views/Moderation/index'

const App = () => (
  <Router basename="/">
    <Aux>
      <Route exact path="/" component={Home} />
      <Route path="/login" component={Login} />
      <Route path="/chat-mod" component={Moderation} />
    </Aux>
  </Router>
)

export default App
